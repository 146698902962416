import MarkdownIt from 'markdown-it'
import type { PluginSimple, Options as MarkdownItOptions } from 'markdown-it'
import { computed, h, ref } from 'vue'
import type { PropType } from 'vue'
export type { Options } from 'markdown-it'

const MarkdownRenderer = defineNuxtComponent({
  name: 'MarkdownRenderer',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    source: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<MarkdownItOptions>,
      required: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    plugins: {
      type: Array as PropType<PluginSimple[]>,
      required: false,
    },
  },
  setup(props) {
    const md = ref(new MarkdownIt(props.options ?? {}))

    for (const plugin of props.plugins ?? []) {
      md.value.use(plugin)
    }

    const content = computed(() =>
      props.inline
        ? md.value.renderInline(props.source)
        : md.value.render(props.source)
    )

    return () => h(props.tag, { innerHTML: content.value })
  },
})

export default MarkdownRenderer
